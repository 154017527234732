import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"

class LiveProject extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-live-project inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center"></h2>
            </div>

            <article className="article project inuit-u-p0">
                <br/><br/>
                <div className="article__body">
                    <h1 className="article__heading">LIVE PROJECTS - Gestion de projet et CRM.</h1>
                    <h2 className="article__heading">PRINCIPALES CARACTÉRISTIQUES</h2>
                    <p className="article__para">
                        <h3>Tableau de bord</h3>
                        Aperçu utile des projets ouverts, des factures ouvertes, des paiements reçus et en attente, des tâches, des messages et des événements ouverts.
                    </p>
                    <p className="article__para">
                        <h3>Gestion de projet</h3>
                        Créez et gérez tous vos projets.
                    </p>
                    <p className="article__para">
                        <h3>Tâches du projet</h3>
                        Vous pouvez ajouter des tâches à n’importe quel projet.
                    </p>
                    <p className="article__para">
                        <h3>Gestion des prospection</h3>
                        Créez et gérez toutes vos pistes de prospections et même importez-les à l'aide d'un fichier .csv.
                    </p>
                    <p className="article__para">
                        <h3>Tâches client</h3>
                        Vous pouvez permettre aux clients de créer des tâches.
                    </p>
                    <p className="article__para">
                        <h3>Tâches Minuteries</h3>
                        Chaque agent peut suivre son temps passé sur les tâches et chaque tâche a une feuille de temps dédiée.
                    </p>
                    <p className="article__para">
                        <h3>Feuilles de temps des tâches</h3>
                        Chaque tâche a sa propre feuille de temps.
                    </p>
                    <p className="article__para">
                        <h3>Etapes du projet</h3>
                        Les étapes vous aideront à regrouper vos tâches.
                    </p>
                    <p className="article__para">
                        <h3>Tickets de support</h3>
                        Laissez vos clients ouvrir des tickets pour suivre tout incident.
                    </p>
                    <p className="article__para">
                        <h3>Minuterie de projet</h3>
                        Cela vous aidera à suivre le temps que vous avez passé sur un projet.
                    </p>
                    <p className="article__para">
                        <h3>GANTT Charts sur les Projets</h3>
                        Chaque projet a son propre diagramme de Gantt regroupé par étape ou agents.
                    </p>
                    <p className="article__para">
                        <h3>Téléchargement de fichiers multiples et commentaires de fichiers</h3>
                        Vous pouvez faire un upload de tout type de fichier, comme une capture d'écran, un documment, un zip,... les attribués à un projet et les partager avec votre client.
                    </p>
                    <p className="article__para">
                        <h3>La gestion des clients</h3>
                        Gérez facilement vos clients avec tous les détails dont vous avez besoin.
                    </p>
                    <p className="article__para">
                        <h3>Portail Client</h3>
                        Vos clients peuvent voir le statut de leurs projets et factures.
                    </p>
                    <p className="article__para">
                        <h3>Gestion des factures</h3>
                        Créer et envoyer des factures n'a jamais été aussi simple!
                    </p>
                    <p className="article__para">
                        <h3>Les dépenses</h3>
                        Suivez toutes vos dépenses.
                    </p>
                    <p className="article__para">
                        <h3>Devis</h3>
                        Envoyez des devis à vos clients.
                    </p>
                    <p className="article__para">
                        <h3>Factures récurrentes et abonnements</h3>
                        Créer des abonnements et établir des factures récurrentes n'a jamais été aussi facile.
                    </p>
                    <p className="article__para">
                        <h3>Calendrier</h3>
                        Beau calendrier avec intégration facultative de calendrier Google.
                    </p>
                    <p className="article__para">
                        <h3>Gestion des articles</h3>
                        Gérez vos articles / produits ainsi que leurs prix et détails.
                    </p>
                    <p className="article__para">
                        <h3>Rapports</h3>
                        Un joli tableau pour voir vos revenus, vos dépenses pour une période donnée.
                    </p>
                    <p className="article__para">
                        <h3>Widget d'activité utilisateur</h3>
                        Voir qui est en ligne.
                    </p>
                    <p className="article__para">
                        <h3>Notifications par email</h3>
                        Recevez des notifications par courrier électronique sur les nouveaux messages, l'affectation de projets, etc.
                    </p>
                    <p className="article__para">
                        <h3>Niveaux d'accès utilisateur</h3>
                        Contrôlez l'accès de vos agents aux différents modules.
                    </p>
                    <p className="article__para">
                        <h3>Accès rapide</h3>
                        Ouvrez rapidement un projet ou démarrez / arrêtez le chronomètre à l'aide du widget Accès rapide.
                    </p>
                    <p className="article__para">
                        <h3>Sauvegarde de la base de données</h3>
                        Ne perdez plus aucune donnée!
                    </p>
                    <p className="article__para">
                        <h3>Support multilingue</h3>
                        Prise en charge linguistique complète avec les fichiers de langue.
                    </p>
                    <p className="article__para">
                        <h3>Demande de devis</h3>
                        Le formulaire de demande de devis est accessible sans connexion. Vous pouvez donc envoyer le lien directement à des clients potentiels ou simplement ajouter le lien au site Web de votre entreprise.
                    </p>
                    <p className="article__para">
                        <h3>Messagerie privée</h3>
                        Envoyez des messages privés aux administrateurs et aux clients.
                    </p>

                </div>

                <br /><br />

            </article>
            <WhoAreWe />
        </Layout>
    
  )

}

export default LiveProject
